<template>
  <div
    class="header-wrapper"
    :class="{
      '-transparent': isHeaderTransparent,
      '-scroll-down': scrollDirection === 'down',
      '-scroll-top': scrollDirection === 'top',
      '-scroll-up': scrollDirection === 'up',
      '-menu-open': menuOpen
    }"
    :style="{
      top: `${headerTranslateY}px`
    }"
  >
    <TopMessage
      ref="topMessage"
      class-name="top-message"
      v-if="
        $route.name !== 'page-not-found' &&
          $route.meta &&
          $route.meta.pageType !== 'ChronomasterSport'
      "
    />
    <header
      id="header-site"
      ref="header"
      class="header -global"
      :class="{
        '-open': isMenuSectionOpened,
        '-notransition': menuNoTransition,
        '-transparent': isHeaderTransparent,
        '-invert-logo-mobile-color':
          isHeaderTransparent && isLogoWhiteHomepageMobile,
        '-invert-logo-color': invertLogoColor,
        '-scroll-down': scrollDirection === 'down',
        '-scroll-top': scrollDirection === 'top',
        '-scroll-up': scrollDirection === 'up',
        '-text-white': textColor === 'white'
      }"
      v-click-outside="clickOutside"
    >
      <h1 class="a11y-sr-only">
        Header
      </h1>
      <transition name="fade" :key="route" v-if="route === 'home'">
        <!-- Background element to keep transition consitent with the menu -->
        <div v-show="isMenuSectionOpened" class="header-background"></div>
      </transition>
      <div class="container" v-bsl="isMobile && menuOpen">
        <Burger v-if="$route.name !== 'page-not-found'" />
        <Lang
          :currency="currentView.currency"
          :lang="currentView.lang"
          :menuOpen="menuOpen"
        ></Lang>
        <a
          v-if="phoneNumber"
          :href="`tel:${phoneNumber}`"
          class="header-phone-number-wrapper header-phone-number--desktop"
        >
          <contact-phone-icon />
          <span class="header-phone-number-text">({{ phoneNumber }})</span>
        </a>

        <!-- <DarkModeButton></DarkModeButton> -->
        <transition name="fade">
          <router-link
            :to="`${localizedUrl}`"
            class="logo"
            ref="logo"
            key="1"
            :title="$t('Logo Zenith Watches, to home page')"
            @click.native="$emit('close')"
            v-if="
              (route === 'home' ||
                route === 'collections' ||
                route === 'news-list') &&
                isMobile &&
                !menuOpen
            "
          >
            <template v-if="route === 'home'">
              <!-- SEO -->
              <h1>
                <img
                  :src="logoHomepageMobile"
                  class="-white"
                  :alt="$t('Logo Zenith Watches, to home page')"
                  width="120px"
                  height="45px"
                />
              </h1>
            </template>
            <template v-else>
              <img
                :src="logoUrl"
                class="-white"
                :alt="$t('Logo Zenith Watches, to home page')"
                width="120px"
                height="45px"
              />
            </template>
          </router-link>
          <router-link
            :to="`${localizedUrl}`"
            class="logo"
            key="2"
            ref="logo"
            :title="$t('Logo Zenith Watches, to home page')"
            @click.native="$emit('close')"
            v-else
          >
            <template v-if="route === 'home'">
              <!-- SEO -->
              <h1>
                <img
                  :src="logoUrl"
                  :alt="$t('Logo Zenith Watches, to home page')"
                  width="120px"
                  height="45px"
                />
              </h1>
            </template>
            <template v-else>
              <img
                :src="logoUrl"
                :alt="$t('Logo Zenith Watches, to home page')"
                width="120px"
                height="45px"
              />
            </template>
          </router-link>
        </transition>
        <transition name="fade">
          <template v-if="searchOpen || isMobile || route === 'search'">
            <Search
              :aria="(menuOpen && isMobile) || route === 'search'"
              :collections="nav.watches.collections"
              @close="closeSearch"
            ></Search>
          </template>
        </transition>
        <nav id="navigation">
          <div
            class="menu_selector"
            ref="selector"
            :class="{ '-active': menuActive || menuHover }"
          ></div>
          <ul class="menu" ref="menu" aria-label="Main menu">
            <!-- <li>
            <router-link
              :to="`${localizedUrl}/our-selection/new-models`"
              class="link -small"
              @focus.native="moveSelector"
              @blur.native="moveSelector"
              @mouseover.native.self="moveSelector"
              @mouseleave.native.self="moveSelector"
              :aria-current="menuActive === 1"
              :tabindex="isMobile && !menuOpen ? -1 : 0"
            >
              <span ref="menu4" v-html="$t(nav.menu[0])"></span>
            </router-link>
          </li> -->
            <!-- Watches -->
            <li
              aria-haspopup="true"
              :aria-expanded="
                menuOpen && menuSection === 'watches' ? 'true' : 'false'
              "
              @mouseover="openMenu('watches')"
              @click="openMenu('watches')"
              @mouseleave="closeMenu"
            >
              <button
                type="button"
                class="link -small"
                @focus="moveSelector"
                @blur="moveSelector"
                @mouseover.self="moveSelector"
                @mouseleave.self="moveSelector"
                @keyup.space="openMenu('watches')"
                id="btn-menu-watches"
                aria-controls="menu-watches"
                :tabindex="isMobile && !menuOpen ? -1 : 0"
                :aria-current="menuActive === 2"
                :aria-expanded="
                  menuOpen && menuSection === 'watches' ? 'true' : 'false'
                "
              >
                <h2 ref="menu2" v-text="$t(nav.menu[1])"></h2>
              </button>
              <transition name="fade">
                <div
                  class="menu_rich"
                  id="menu-watches"
                  aria-labelledby="btn-menu-watches"
                  v-show="menuOpen && menuSection === 'watches'"
                  :class="{ '-open': menuOpen && menuSection === 'watches' }"
                >
                  <!-- button class="back" type="button" @click.stop.prevent="openMenu" v-html="$t(nav.menu[1])"></button -->
                  <div class="container -watches">
                    <div class="row">
                      <div class="col-12 col-lg-9">
                        <ul class="collections">
                          <li
                            v-for="(collection, key) in getSectionLinks('watches')"
                            class="collection -watches"
                            :key="key"
                          >
                            <router-link
                              v-if="isSSR"
                              :to="localizedUrl + collection.link"
                            >
                              <h3
                                class="h5"
                                v-if="collection.title"
                                v-text="$t(collection.title)"
                              ></h3>
                              <span
                                v-if="collection.subtitle"
                                v-text="$t(collection.subtitle)"
                              ></span>
                            </router-link>
                            <NoSSR>
                              <collection-item
                                HCi18n="true"
                                :collection="collection"
                                v-if="menuOpen"
                              ></collection-item>
                            </NoSSR>
                          </li>
                        </ul>
                        <router-link
                          class="btn -stroke"
                          :to="`${localizedUrl}/${nav.watches.ctaLink}`"
                          v-text="$t(nav.watches.cta)"
                          @click.native="$emit('close')"
                        ></router-link>
                      </div>
                      <div class="col-12 col-lg-3 aside selection">
                        <ul>
                          <li
                            v-for="(selection, key) in nav.watches.selections
                              .items"
                            :key="key"
                          >
                            <router-link
                              :to="`${localizedUrl}/${selection.link}`"
                              v-text="$t(selection.title)"
                              @click.native="$emit('close')"
                              tag="h3"
                            />
                          </li>
                        </ul>
                        <div
                          class="limited col-12 col-md-6 col-lg-12"
                          v-if="false"
                        >
                          <strong
                            v-html="$t(nav.watches.limited.title)"
                            class="title"
                          ></strong>
                          <router-link
                            :to="`${localizedUrl}/${nav.watches.limited.link}`"
                            @click.native="$emit('close')"
                          >
                            <lazy-image
                              class-name="limited-edition-image"
                              :item="limitedEditionPicture"
                              :sizes="limitedEditionPictureSizes"
                              rootMargin="0px"
                            ></lazy-image>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>
            <!-- Straps -->
            <li
              v-if="strapActivated"
              aria-haspopup="true"
              :aria-expanded="
                menuOpen && menuSection === 'straps' ? 'true' : 'false'
              "
              @mouseover="openMenu('straps')"
              @click="openMenu('straps')"
              @mouseleave="closeMenu"
            >
              <router-link
                :to="`${localizedUrl}/products/straps`"
                class="link -small"
                @focus.native="moveSelector"
                @blur.native="moveSelector"
                @mouseover.native.self="moveSelector"
                @mouseleave.native.self="moveSelector"
                :aria-current="menuActive === 5"
                :tabindex="isMobile && !menuOpen ? -1 : 0"
              >
                <h2 ref="menu2" v-text="$t(nav.menu[5])"></h2>
              </router-link>
              <!-- button
              type="button"
              class="link -small"
              @focus="moveSelector"
              @blur="moveSelector"
              @mouseover.self="moveSelector"
              @mouseleave.self="moveSelector"
              @keyup.space="openMenu('straps')"
              id="btn-menu-straps"
              aria-controls="menu-straps"
              :tabindex="isMobile && !menuOpen ? -1 : 0"
              :aria-current="menuActive === 5"
              :aria-expanded="menuOpen && menuSection === 'straps' ? 'true' : 'false'"
            >
              <span ref="menu2" v-html="$t(nav.menu[5])"></span>
            </button>
            <transition name="fade">
              <div class="menu_rich" id="menu-straps" aria-labelledby="btn-menu-straps" v-show="menuOpen && menuSection === 'straps'" :class="{ '-open': menuOpen && menuSection === 'straps' }">
                <button class="back" type="button" @click.stop.prevent="openMenu" v-html="$t(nav.menu[5])"></button>
                <div class="container -straps" v-bsl="isMobile && menuOpen && menuSection === 'straps'">
                  <div class="row">
                    <div class="col-12 col-md-8" v-if="bannerStrapPicture">
                      <router-link class="banner" :to="`${localizedUrl}/${nav.straps.cta.link}`" @click.native="$emit('close')">
                        <strong v-text="$t(nav.straps.cta.title)"></strong>
                        <NoSSR>
                          <lazy-image :item="bannerStrapPicture" :sizes="bannerStrapPictureSizes" rootMargin="0px"></lazy-image>
                        </NoSSR>
                      </router-link>
                    </div>
                    <div class="col-12 col-md-3 offset-md-1 aside straps">
                      <strong class="title" v-html="$t(nav.straps.title)"></strong>
                      <p class="text" v-html="$t(nav.straps.text)"></p>
                    </div>
                  </div>
                </div>
              </div>
            </transition -->
            </li>
            <!-- La Maison -->
            <li
              aria-haspopup="true"
              :aria-expanded="
                menuOpen && menuSection === 'brand' ? 'true' : 'false'
              "
              @mouseover="openMenu('brand')"
              @click="openMenu('brand')"
              @mouseleave="closeMenu"
            >
              <button
                type="button"
                class="link -small"
                @focus="moveSelector"
                @blur="moveSelector"
                @mouseover.self="moveSelector"
                @mouseleave.self="moveSelector"
                @keyup.space="openMenu('brand')"
                id="btn-menu-brand"
                aria-controls="menu-brand"
                :tabindex="isMobile && !menuOpen ? -1 : 0"
                :aria-current="menuActive === 3"
                :aria-expanded="
                  menuOpen && menuSection === 'brand' ? 'true' : 'false'
                "
              >
                <h2 ref="menu3" v-text="$t(nav.menu[2])"></h2>
              </button>
              <transition name="fade">
                <div
                  class="menu_rich"
                  id="menu-brand"
                  aria-labelledby="btn-menu-brand"
                  v-show="menuOpen && menuSection === 'brand'"
                  :class="{ '-open': menuOpen && menuSection === 'brand' }"
                >
                  <button
                    class="back"
                    type="button"
                    @click.stop.prevent="openMenu"
                    v-html="$t(nav.menu[2])"
                  ></button>
                  <div class="container">
                    <div class="row">
                      <div class="col-12 col-lg-9">
                        <ul class="collections">
                          <li
                            v-for="(collection, key) in getSectionLinks('brand')"
                            class="collection"
                            :key="key"
                          >
                            <router-link
                              v-if="isSSR"
                              :to="localizedUrl + collection.link"
                            >
                              <h3
                                class="h5"
                                v-if="collection.title"
                                v-text="$t(collection.title)"
                              ></h3>
                              <span
                                v-if="collection.subtitle"
                                v-text="$t(collection.subtitle)"
                              ></span>
                            </router-link>
                            <NoSSR>
                              <collection-item
                                HCi18n="true"
                                :collection="collection"
                                v-if="menuOpen"
                              ></collection-item>
                            </NoSSR>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-lg-3 aside selection">
                        <ul>
                          <li
                            v-for="(selection, key) in nav.brand.selections
                              .items"
                            :key="key"
                          >
                            <h3
                              v-if="selection.ext"
                              :href="selection.link"
                              target="_blank"
                              rel="noreferrer noopener"
                              v-text="$t(selection.title)"
                            ></h3>

                            <router-link
                              v-else
                              :to="`${localizedUrl}/${selection.link}`"
                              v-text="$t(selection.title)"
                              @click.native="$emit('close')"
                              tag="h3"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>
            <!-- Store Locator -->
            <li
              :aria-expanded="
                menuOpen && menuSection === 'store-locator' ? 'true' : 'false'
              "
              @mouseover="openMenu('store-locator')"
              @mouseleave="closeMenu"
            >
              <button
                class="link -small"
                @focus="moveSelector"
                @blur="moveSelector"
                @mouseover.self="moveSelector"
                @mouseleave.self="moveSelector"
              >
                <router-link
                  :to="`${localizedUrl}/store-locator`"
                  :aria-current="menuActive === 4"
                  :tabindex="isMobile && !menuOpen ? -1 : 0"
                >
                  <h2 ref="menu4" v-text="$t(nav.menu[3])"></h2>
                </router-link>
              </button>
            </li>
            <!-- Services -->
            <li
              aria-haspopup="true"
              :aria-expanded="
                menuOpen && menuSection === 'services' ? 'true' : 'false'
              "
              @mouseover="openMenu('services')"
              @click="openMenu('services')"
              @mouseleave="closeMenu"
            >
              <button
                type="button"
                class="link -small"
                @focus="moveSelector"
                @blur="moveSelector"
                @mouseover.self="moveSelector"
                @mouseleave.self="moveSelector"
                @keyup.space="openMenu('services')"
                id="btn-menu-services"
                aria-controls="menu-services"
                :aria-current="menuActive === 5"
                :tabindex="isMobile && !menuOpen ? -1 : 0"
                :aria-expanded="
                  menuOpen && menuSection === 'services' ? 'true' : 'false'
                "
              >
                <h2 ref="menu5" v-text="$t(nav.menu[4])"></h2>
              </button>
              <transition name="fade">
                <div
                  id="menu-services"
                  aria-labelledby="btn-menu-services"
                  class="menu_rich"
                  v-show="menuOpen && menuSection === 'services'"
                  :class="{ '-open': menuOpen && menuSection === 'services' }"
                >
                  <button
                    class="back"
                    type="button"
                    @click.stop.prevent="openMenu"
                    v-html="$t(nav.menu[4])"
                  ></button>
                  <div class="container">
                    <div class="row">
                      <div class="col-12 col-lg-9">
                        <ul class="collections">
                          <li
                            v-for="(collection, key) in getSectionLinks('services')"
                            class="collection"
                            :key="key"
                          >
                            <router-link
                              v-if="isSSR"
                              :to="localizedUrl + collection.link"
                            >
                              <h3
                                class="h5"
                                v-if="collection.title"
                                v-text="$t(collection.title)"
                              ></h3>
                              <span
                                v-if="collection.subtitle"
                                v-text="$t(collection.subtitle)"
                              ></span>
                            </router-link>
                            <NoSSR>
                              <collection-item
                                HCi18n="true"
                                :collection="collection"
                                v-if="menuOpen"
                              ></collection-item>
                            </NoSSR>
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-lg-3 aside selection">
                        <ul>
                          <li
                            v-for="(selection, key) in nav.services.selections
                              .items"
                            :key="key"
                          >
                            <router-link
                              :to="`${localizedUrl}/${selection.link}`"
                              v-text="$t(selection.title)"
                              @click.native="$emit('close')"
                              tag="h3"
                            />
                          </li>
                        </ul>
                        <ContactInfoSection />
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </li>
            <!-- Wechat only China -->
            <li v-if="localizedUrl === '/zh_cn'">
              <button
                type="button"
                class="link -small -cn"
                @focus="moveSelector"
                @blur="moveSelector"
                @mouseover.self="moveSelector"
                @mouseleave.self="moveSelector"
                @keyup.space="tooltipCn = true"
                aria-controls="modal-wechat"
                :aria-expanded="tooltipCn ? 'true' : 'false'"
                @click="tooltipCn = true"
                id="btn-menu-cn"
              >
                <h2 ref="menu6">线上精品店</h2>
              </button>
              <modal
                id="wechat"
                :isOpen="tooltipCn"
                @close="
                  () => {
                    tooltipCn = false
                  }
                "
              >
                <div class="text-center">
                  <img src="/assets/img/tmp/qrcode-2.jpg" :alt="$t('WeChat')" />
                </div>
              </modal>
            </li>

            <li class="header-phone-number--mobile">
              <a v-if="phoneNumber" :href="`tel:${phoneNumber}`" class="link">
                <contact-phone-icon />
                <span class="header-phone-number-text"
                  >({{ phoneNumber }})</span
                >
              </a>
            </li>
          </ul>
          <ul class="utils">
            <li v-if="route !== 'search'">
              <button
                type="button"
                class="search"
                @click="toggleSearch"
                :aria-label="$t('Search')"
                aria-haspopup="true"
                :aria-expanded="searchOpen ? 'true' : 'false'"
              >
                <i class="icon-loupe"></i>
              </button>
            </li>
            <li v-if="accountActivated">
              <router-link
                @focus.native="leaveMenuMobile"
                :aria-label="$t('My wishlist')"
                :to="
                  isLoggedIn
                    ? `${this.localizedUrl}/my-profile?tab=wishlist`
                    : `${this.localizedUrl}/login`
                "
              >
                <i class="icon-fav"></i>
                <counter
                  :value="itemsInWishlist"
                  :active="itemsInWishlist"
                  :trad="$t('Product in wishlist')"
                ></counter>
              </router-link>
            </li>
            <li v-show="ecommerceActivated">
              <router-link
                :aria-label="$t('Shopping cart')"
                :to="`${localizedUrl}/cart`"
              >
                <i class="icon-cart"></i>
                <counter
                  :value="itemsInCart"
                  :active="itemsInCart"
                  :trad="$t('Product in cart')"
                ></counter>
              </router-link>
            </li>
            <li v-if="accountActivated || isQRStore">
              <router-link
                :aria-label="$t('My profile')"
                :to="
                  isLoggedIn
                    ? `${this.localizedUrl}/my-profile`
                    : `${this.localizedUrl}/login`
                "
              >
                <i class="icon-account"></i>
              </router-link>
              <LoginTooltip :menuOpen="menuOpen"/>
            </li>
          </ul>
        </nav>
      </div>
      <!-- Toolbar to inform user of action -->
      <Feedback v-if="show" show="false"></Feedback>
    </header>
  </div>
</template>

<script>
import Counter from './Counter'
import Search from './Search'
// import Lang from './Lang'
import { getRouteName, DISABLED_URLS_STORES } from '../utilities'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { mapGetters, mapActions } from 'vuex'
// import CollectionItem from './CollectionItem'
import NoSSR from 'src/themes/zenith/node_modules/vue-no-ssr'
// import Feedback from './utils/Feedback'
import { ReadyHook } from 'vue-asyncready'
// import DarkModeButton from './utils/DarkModeButton'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'
import LazyImage from './utils/LazyImage'
import { isServer } from '@vue-storefront/core/helpers'
// import Modal from '../components/utils/Modal'

import TopMessage from 'src/themes/zenith/components/TopMessage'
import Burger from 'src/themes/zenith/components/Burger'

import ContactPhoneIcon from './header/ContactPhoneIcon'
import ContactInfoSection from './ContactInfoSection.vue'
import LoginTooltip from './header/LoginTooltip.vue';

let headerHeight
let elVirtualContainer

const DEFAULT_TEXT_COLOR = 'black'

export default {
  name: 'Header',
  mixins: [ReadyHook],
  components: {
    Counter,
    NoSSR,
    Search,
    TopMessage,
    Burger,
    ContactPhoneIcon,
    ContactInfoSection,
    LoginTooltip,
    // Lang,
    // Feedback,
    // DarkModeButton,
    CollectionItem: () =>
      import(/* webpackChunkName: "collection-item-cpt" */ './CollectionItem'),
    // Search: () => import(/* webpackChunkName: "search-header-cpt" */ './Search'),
    Lang: () => import(/* webpackChunkName: "lang-header-cpt" */ './Lang'),
    Feedback: () =>
      import(/* webpackChunkName: "editorial-header-cpt" */ './utils/Feedback'),
    // DarkModeButton: () => import(/* webpackChunkName: "darkmodebutton-header-cpt" */ './utils/DarkModeButton'),
    // ImageManager,
    LazyImage,
    Modal: () =>
      import(/* webpackChunkName: "modal-header-cpt" */ './utils/Modal')
  },
  data() {
    return {
      isSSR: true,
      ecommerceActivated: null,
      menuHover: null,
      menuRich: null,
      scrollDirection: 'top',
      menuShow: false,
      searchActive: false,
      lastScrollPos: 0,
      menuThrottle: null,
      menuNoTransition: false,
      currentTranslateHeader: 0,
      tooltipCn: false,
      dir: '',
      lang: 'English',
      currency: 'USD',
      links: [],
      focused: 0,
      isVScroll: false,
      textColor: DEFAULT_TEXT_COLOR,
      nav: {
        menu: [
          'New models',
          'Watches',
          'The brand',
          'Stores',
          'Services',
          'Straps'
        ],
        watches: {
          cta: 'See all zenith watches',
          ctaLink: 'products/watches',
          collections: [
            {
              title: 'Defy',
              subtitle: 'Innovation and accuracy',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/watches/skyline.png',
                  'image',
                  'static'
                ),
                alt: 'Defy',
                width: 100,
                height: 154
              },
              link: '/products/defy'
            },
            {
              title: 'Chronomaster',
              subtitle: 'Iconic and timeless',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/watches/cms.png',
                  'image',
                  'static'
                ),
                alt: 'Chronomaster',
                width: 100,
                height: 154
              },
              link: '/products/chronomaster'
            },
            {
              title: 'Pilot',
              subtitle: 'Reliability and impact',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/watches/pilot.png',
                  'image',
                  'static'
                ),
                alt: 'Pilot',
                width: 100,
                height: 154
              },
              link: '/products/pilot'
            },
            {
              title: 'Elite',
              subtitle: 'Refinement and simplicity',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/watches/elite.png',
                  'image',
                  'static'
                ),
                alt: 'Elite',
                width: 100,
                height: 154
              },
              link: '/products/elite'
            },
            {
              title: 'Icons',
              subtitle: 'Certified Vintage',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/watches/icons.png',
                  'image',
                  'static'
                ),
                alt: 'Icons',
                width: 100,
                height: 154
              },
              link: '/products/icons'
            }
          ],
          selections: {
            title: 'Our selection',
            items: [
              {
                title: 'Chronomaster Sport',
                link: '/brand/chronomaster-sport'
              },
              {
                title: 'Defy Skyline',
                link: 'defy-skyline'
              },
              {
                title: 'DEFY DIVER',
                link: 'brand/defy-diver'
              },
              {
                title: 'Pilot',
                link: 'brand/pilot'
              },
              {
                title: 'MASTER OF CHRONOGRAPHS',
                link: 'brand/master-of-chronographs'
              },
              {
                title: 'ICONS EPILOGUE',
                link: 'brand/zenith-icons-epilogue'
              },
              {
                title: 'Pantone',
                link: '/brand/pantone-chapter3'
              }
            ]
          },
          limited: {
            title: 'Limited Editions',
            img: ImageManager.convert(
              '/assets/img/tmp/nav_limited.jpg',
              'image',
              'static'
            ),
            link:
              'product/chronomaster-revival-a3818-cover-girl-03-a3818-400-51-m3818',
            width: 300,
            height: 168
          }
        },
        straps: {
          title: 'In oculis quidem',
          text:
            'A change of straps can go a long way in giving your Zenith watch a personalized touch. With Zenith’s quick strap-change system, a new look is just a few clicks away.',
          cta: {
            title: 'Magna carta straps',
            link: 'products/straps',
            image: {
              url: ImageManager.convert(
                '/assets/img/tmp/nav_limited.jpg',
                'image',
                'static'
              ),
              alt: 'Straps',
              width: 100,
              height: 154
            }
          }
        },
        brand: {
          collections: [
            {
              title: 'HISTORY',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/history.png',
                  'image',
                  'static'
                ),
                filter: '',
                alt: 'History',
                width: 100,
                height: 154
              },
              link: '/brand/timeline'
            },
            {
              title: 'Time to reach your star',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/TTRYS.png',
                  'image',
                  'static'
                ),
                filter: '',
                alt: 'Time to reach your star',
                width: 100,
                height: 154
              },
              link: '/brand/time-to-reach-your-star'
            },
            {
              title: 'SAVOIR-FAIRE',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/savoir-faire.png',
                  'image',
                  'static'
                ),
                filter: '',
                alt: 'Savoir-faire',
                width: 100,
                height: 154
              },
              link: '/brand/savoir-faire'
            },
            {
              title: 'COMMITMENTS',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/commitments.png',
                  'image',
                  'static'
                ),
                filter: '',
                alt: 'commitments',
                width: 100,
                height: 154
              },
              link: '/brand/commitments'
            }
          ],
          selections: {
            items: [
              {
                title: 'WATCH CLINIC',
                link: 'brand/watch-clinic'
              },
              {
                title: 'Dreamhers',
                link: 'brand/dreamhers'
              },
              {
                title: '360° virtual manufacture experience',
                link: 'brand/360-manufacture-video-experience'
              }
            ]
          }
        },
        services: {
          collections: [
            {
              title: 'Maintenance and restoration',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/savoir-faire.png',
                  'image',
                  'static'
                ),
                filter: '',
                alt: 'Maintenance and restoration',
                width: 100,
                height: 154
              },
              link: '/service/maintenance-and-services'
            },
            {
              title: 'Send us your watch',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/service_return.jpg',
                  'image',
                  'static'
                ),
                filter: '',
                alt: 'Send us your watch',
                width: 100,
                height: 154
              },
              link: '/service/return-your-watch-to-manufacture'
            },
            {
              title: 'Extend your warranty',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/service_register.jpg',
                  'image',
                  'static'
                ),
                filter: '',
                alt: 'Extend your warranty',
                width: 100,
                height: 154
              },
              link: this.isLoggedIn ? '/register' : '/login?register'
            },
            {
              title: 'Extracts and certificates',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/service_extracts.jpg',
                  'image',
                  'static'
                ),
                filter: '',
                alt: 'Extracts and certificates',
                width: 100,
                height: 154
              },
              link: '/service/extracts-certificates'
            }/*,
            {
              title: 'Care',
              image: {
                url: ImageManager.convert(
                  '/assets/img/nav/service_maintenance.jpg',
                  'image',
                  'static'
                ),
                filter: '',
                alt: 'Care',
                width: 100,
                height: 154
              },
              link: '/care'
            } */
          ],
          selections: {
            items: [
              {
                title: 'International warranty',
                link: 'service/international-warranty'
              },
              {
                title: 'Catalogue',
                link: 'service/catalogue'
              },
              {
                title: 'User manuals',
                link: 'page/user-manuals'
              },
              {
                title: 'Contact us',
                link: 'service/contact-customer-care'
              }
            ]
          }
        }
      }
    }
  },
  async serverPrefetch() {
    if (getRouteName(this.$route.name) === 'home') {
      const prismicOptions = { uid: 'homepage', type: 'homepage' }
      const prefetchedPage = this.getPage(prismicOptions)
      let page

      if (prefetchedPage) {
        page = prefetchedPage
      } else {
        await this.setPage(prismicOptions)
        page = this.getPage(prismicOptions)
      }

      if (page) {
        this.textColor = page.main.headerTextColor || DEFAULT_TEXT_COLOR
      }
    }
  },
  beforeMount() {
    this.$bus.$on('header-fixed', () => {
      this.menuShow = true
    })
    this.$bus.$on('vscroll-created', () => {
      this.onVScrollCreated()
    })
    this.$bus.$on('vscroll-destroyed', () => {
      this.onVScrollDestroyed()
    })
  },
  mounted() {
    this.isSSR = isServer
    if (this.menuActive) {
      this.moveSelector()
    }
    // this.onScrollWindow(0)

    this.setMobile(window.innerWidth < 768)

    window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.onScroll)
    document.addEventListener('keyup', this.keyup)
    document.addEventListener('keydown', this.keydown)
  },
  watch: {
    menuOpen() {
      this.focused = 0
      this.$nextTick(() => {
        if (!this.isMobile && this.menuOpen) {
          this.links = this.$el.querySelectorAll(
            '#menu-' + this.menuSection + ' a'
          )
          if (this.links[this.focused]) {
            this.links[this.focused].focus()
          }
        }
      })
    },
    $route(to) {
      this.setPageHeaderColor(to.name)
    }
  },
  computed: {
    ...mapGetters('feedback', ['show']),
    headerTranslateY() {
      if (this.menuOpen) {
        return '0'
      }

      if (this.scrollDirection !== 'top') {
        if (this.$refs.topMessage) {
          const topMessageHeight = this.$refs.topMessage.$el.clientHeight
          const offset = parseInt(topMessageHeight) + 2
          return '-' + offset
        }
      }

      return '0'
    },
    logoUrl() {
      return this.getLogoUrl()
    },
    phoneNumber() {
      return currentStoreView().contactPhone
    },
    isLogoWhiteHomepageMobile() {
      return this.getIsLogoWhiteInHomeMobile()
    },
    logoHomepageMobile() {
      const logoUrl = this.getLogoUrl()

      if (this.getIsLogoWhiteInHomeMobile()) {
        return '/assets/img/logo_white.svg'
      }

      return logoUrl
    },
    isHeaderTransparent() {
      const route = getRouteName(this.$route.name)

      return (
        route === 'home' || route === 'collections' || route === 'news-list'
      )
    },
    invertLogoColor() {
      return (
        this.isHeaderTransparent &&
        this.$store.state['zenith-ui'].logo.invertLogoOnTransparentHeader
      )
    },
    isMobile() {
      return this.$store.state['zenith-ui'].menu.mobile
    },
    menuOpen() {
      return this.$store.state['zenith-ui'].menu.open
    },
    isMenuSectionOpened() {
      return this.menuOpen || this.searchOpen || this.langOpen
    },
    langOpen() {
      return this.$store.state['zenith-ui'].lang.open
    },
    menuSection() {
      return this.$store.state['zenith-ui'].menu.section
    },
    searchOpen() {
      return this.$store.state['zenith-ui'].search.open
    },
    strapActivated() {
      const { isStrapActivated } = currentStoreView()
      return isStrapActivated
    },
    currentView() {
      const { i18n } = currentStoreView()
      const { fullLanguageName, currencyCode } = i18n
      return {
        lang: fullLanguageName,
        currency: currencyCode
      }
    },
    ...mapGetters('user', ['isLoggedIn']),
    ...mapGetters('cart', ['getItemsTotalQuantity']),
    ...mapGetters('wishlist-extend', ['getWishlistLength']),
    ...mapGetters('prismic', ['getPage']),
    itemsInCart() {
      return this.getItemsTotalQuantity
    },
    itemsInWishlist() {
      return this.getWishlistLength
    },
    accountActivated() {
      const { account } = currentStoreView()
      return account !== false
    },
    isQRStore() {
      const { isQRStore } = currentStoreView()
      return isQRStore === true
    },
    localizedUrl() {
      return currentStoreView().url || ''
    },
    route() {
      return getRouteName(this.$route.name)
    },
    menuActive() {
      switch (this.route) {
        case 'selection':
          return 1
        case 'collections':
          return 2
        case 'brand':
          return 3
        case 'store-locator':
          return 4
        case 'service':
          return 5
        case 'straps':
          return 6
        default:
          return null
      }
    },
    limitedEditionPicture() {
      return {
        url: this.nav.watches.limited.img,
        lowDefinitionUrl: ImageManager.getLowDefinition(
          this.nav.watches.limited.img,
          40,
          0.5614035088
        ),
        srcset: ImageManager.getSrcSet(
          this.nav.watches.limited.img,
          600,
          100,
          50,
          0.5614035088
        ),
        alt: this.nav.watches.limited.title,
        width: 285,
        height: 160
      }
    },
    limitedEditionPictureSizes() {
      return '(max-width: 992px) 21vw, (max-width: 1600px) 16vw, 42vw'
    },
    bannerStrapPicture() {
      return {
        url: this.nav.straps.cta.image.url,
        lowDefinitionUrl: ImageManager.getLowDefinition(
          this.nav.straps.cta.image.url,
          790,
          0.30379747
        ),
        srcset: ImageManager.getSrcSet(
          this.nav.straps.cta.image.url,
          600,
          100,
          50,
          0.30379747
        ),
        alt: this.nav.straps.cta.image.alt,
        width: 790,
        height: 240
      }
    },
    bannerStrapPictureSizes() {
      return '(max-width: 992px) 100vw, (max-width: 1600px) 100vw, 50vw'
    }
  },
  methods: {
    ...mapActions('wishlist-extend', ['loadWishlist']),
    ...mapActions('zenith-ui', ['isEcommerce']),
    ...mapActions('prismic', ['setPage']),
    getSectionLinks(sectionName) {
      const storeCode = currentStoreView().storeCode
      if (DISABLED_URLS_STORES.hasOwnProperty(storeCode)) {
        return this.nav[sectionName].collections.filter(
          collection => !DISABLED_URLS_STORES[storeCode].includes(collection.link)
        )
      }
      if (this.nav[sectionName]) {
        return this.nav[sectionName].collections
      }
      return []
    },
    keydown(e) {
      // For AA compliant Keyboard navigation
      if ((e.keyCode === 37 || e.keyCode === 38) && this.menuOpen) {
        e.preventDefault()
        this.focused =
          this.focused - 1 < 0 ? this.links.length - 1 : this.focused - 1
        this.links[this.focused].focus()
      }
      if ((e.keyCode === 39 || e.keyCode === 40) && this.menuOpen) {
        e.preventDefault()
        this.focused =
          this.focused + 1 >= this.links.length ? 0 : this.focused + 1
        this.links[this.focused].focus()
      }
    },
    keyup(e) {
      if (e.keyCode === 27) {
        this.closeMenu()
        if (this.isMobile) {
          document.querySelector('.burger').focus()
        }
      }
    },
    clickOutside(e) {
      this.closeSearch()
    },
    onResize(e) {
      this.setMobile(window.innerWidth < 768)
      this.moveSelector(e)
    },
    onScroll() {
      this.handleScroll()
    },
    closeSearch() {
      if (this.searchOpen) {
        this.$store.dispatch('zenith-ui/closeSearch')
        this.$store.dispatch('zenith-ui/setBodyOverflow')
      }
    },
    openMenu(item = null) {
      clearTimeout(this.menuThrottle)
      this.menuThrottle = setTimeout(() => {
        this.$store.commit('zenith-ui/setMenu', {
          open: true,
          section: item
        })
        clearTimeout(this.menuThrottle)
      }, 200)
    },
    closeMenu(e) {
      if (!this.isMobile || (this.isMobile && e.type !== 'mouseleave')) {
        clearTimeout(this.menuThrottle)
        this.menuThrottle = setTimeout(() => {
          this.$store.dispatch('zenith-ui/closeMenu')
          clearTimeout(this.menuThrottle)
        }, 200)
      }
    },
    setMobile(mobile) {
      this.$store.commit('zenith-ui/setMobile', mobile)
    },
    toggleSearch() {
      this.$store.commit('zenith-ui/setSearch', !this.searchOpen)
      this.$store.dispatch('zenith-ui/setBodyOverflow')
    },
    leaveMenuMobile() {
      if (this.isMobile) {
        this.closeMenu()
      }
    },
    // Reposition tabs_selector
    moveSelector(el) {
      // let rect = el && (el.type === 'mouseover' || el.type === 'focus') ? el.target.querySelector('span') : this.$refs['menu' + this.menuActive]
      // let time = el && el.type !== 'resize' ? '0.2s' : '0s'
      let rect = ''
      if (el && el.type === 'focus') {
        if (!this.isMobile) {
          this.closeMenu()
        } else {
          if (this.menuSection) {
            this.openMenu()
          }
        }
      }
      if (el && (el.type === 'mouseover' || el.type === 'focus')) {
        rect = el.target.querySelector('h2')
        this.menuHover = true
      } else {
        rect = this.$refs['menu' + this.menuActive]
        this.menuHover = false
      }
      if (rect) {
        let left = rect.getBoundingClientRect().left
        if (window.innerWidth > 1200) {
          left -= (document.body.clientWidth - 1200) / 2
        }
        // this.$refs.selector.style.transitionDuration = time
        this.$refs.selector.style.left = left + 'px'
        this.$refs.selector.style.width = rect.offsetWidth + 'px'
      }
    },
    handleScroll() {
      const scrollY = window.scrollY

      const scrollDownOffset = this.lastScrollPos + 40
      const scrollUpOffset = this.isMobile
        ? this.lastScrollPos - 20
        : this.lastScrollPos - 100

      if (scrollY < 5) {
        this.scrollDirection = 'top'
      } else if (scrollY >= scrollDownOffset) {
        this.scrollDirection = 'down'
      } else if (scrollY < scrollUpOffset) {
        this.scrollDirection = 'up'
      }
      this.lastScrollPos = scrollY
    },
    // Utils for Chronomaster Landing (pages/CollectionLanding.vue)
    onVScrollCreated() {
      if (window.vscroll) {
        this.isVScroll = true
        this.menuNoTransition = true
        headerHeight = document.querySelector('.top-msg')
          ? document.querySelector('.top-msg').clientHeight +
            this.$el.clientHeight
          : this.$el.clientHeight
        this.currentTranslateHeader = headerHeight
        elVirtualContainer = document.querySelector('.chrono-container')
        elVirtualContainer.style.transform = `translateY(${this.currentTranslateHeader}px)`
        // elVirtualContainer.style.height = `${window.innerHeight - this.currentTranslateHeader}px`
        // elVirtualContainer.style.transition = 'none'
        if (this.$refs.header) {
          this.$refs.header.style.transform = `translateY(${this.currentTranslateHeader}px)`
        }
        this.$refs.header.style.transition = 'transform 0.3s ease-out'
        elVirtualContainer.style.transition = 'transform 0.3s ease-out'
        window.vscroll.on('scroll', this.onVirtualScroll)
      }
    },
    onVScrollDestroyed() {
      if (this.$refs.header) {
        this.$refs.header.style.removeProperty('transform')
      }
    },
    onVirtualScroll(objScroll) {
      const direction = objScroll.direction
      let scrollY = objScroll.scroll.y
      let scrollHeader = headerHeight - scrollY

      if (scrollY > headerHeight) {
        // this.menuNoTransition = false
        // elVirtualContainer.style.transition = 'transform 0.2s ease-in-out, height 0.2s ease-in-out'
        scrollHeader = 0
        if (direction === 'up') {
          scrollHeader = headerHeight
        }
      } else {
        // this.menuNoTransition = true
        // elVirtualContainer.style.transition = 'none'
        if (direction === 'up') {
          if (this.currentTranslateHeader >= headerHeight) {
            scrollHeader = headerHeight
          }
        }
      }

      if (this.currentTranslateHeader !== scrollHeader) {
        this.currentTranslateHeader = scrollHeader
        elVirtualContainer.style.transform = `translateY(${this.currentTranslateHeader}px)`
        // elVirtualContainer.style.height = `${window.innerHeight - this.currentTranslateHeader}px`
        if (this.$refs.header) {
          this.$refs.header.style.transform = `translateY(${this.currentTranslateHeader}px)`
        }
      }
    },
    setPageHeaderColor(route) {
      if (getRouteName(route) !== 'home') {
        this.textColor = DEFAULT_TEXT_COLOR
      } else {
        const page = this.getPage({ uid: 'homepage', type: 'homepage' })

        if (page && page.main) {
          this.textColor = page.main.headerTextColor || DEFAULT_TEXT_COLOR
        } else {
          this.textColor = DEFAULT_TEXT_COLOR
        }
      }
    },
    getLogoUrl() {
      const logo = this.$store.state['zenith-ui'].logo.url
      const fallback = '/assets/img/logo_black.svg'

      return logo || fallback
    },
    getIsLogoWhiteInHomeMobile() {
      return this.$store.state['zenith-ui'].logo.homepageWhiteLogoMobile
    }
    // END Utils for Chronomaster Landing
  },
  created() {
    // this.$nextTick(() => {
    //   this.ecommerceActivated = this.$store.state['zenith-ui'].ecommerce
    // })
    this.isEcommerce().then(result => (this.ecommerceActivated = result))

    this.setPageHeaderColor(this.$route.name)
  },
  destroyed: function() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
    document.removeEventListener('keyup', this.keyup)
    document.removeEventListener('keydown', this.keydown)
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.header-wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: transform 0.2s ease-in-out;

  &:not(.-transparent) {
    background: var(--zenith-header-background);
  }

  &.-scroll-top {
    transition: none;
  }

  &.scroll-down {
    .top-message {
      display: none;
    }
  }

  @include bp(max md) {
    &.-menu-open {
      .top-message {
        display: none;
      }
    }
  }
}

.header-background {
  position: absolute;
  z-index: var(--background-z-index);
  background: var(--zenith-header-background);
  top: 0;
  width: 100%;
  height: 100%;
}

.header {
  position: relative;
  width: 100%;
  background: var(--zenith-header-background);
  transition: background-color 0.2s ease-in-out;
  --navigation-links-color: #000000;
  --zenith-header-text-link: #000000;
  --background-z-index: 1;
  --content-z-index: 2;

  &.-invert-logo-mobile-color {
    @include bp(max md) {
      &.-scroll-up.-transparent:not(.-open) {
        .logo {
          img {
            filter: invert(100);
          }
        }
      }

      &.-scroll-down.-transparent:not(.-open) {
        .logo {
          img {
            filter: invert(100);
          }
        }
      }
    }
  }

  &.-scroll-up,
  &.-scroll-down {
    background-color: var(--zenith-header-background);
  }
  &.-scroll-down {
    &:not(.-open) {
      .lang,
      .header-phone-number-wrapper {
        display: none;
      }

      @include bp(md) {
        #navigation {
          display: none;
        }
      }
    }

    .logo {
      padding-bottom: 1.8rem;
    }
  }

  &.-transparent {
    &:not(.-scroll-down):not(.-scroll-up) {
      background-color: transparent;
    }

    &:not(.-open):not(.-scroll-up):not(.-scroll-down) {
      @include bp(max md) {
        .utils li a {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  &.-transparent.-text-white:not(.-open):not(.-scroll-up):not(.-scroll-down) {
    background: linear-gradient(to bottom, #5c5c5c 0, rgba(0, 0, 0, 0) 100%);
  }

  @include bp(md) {
    &.-text-white:not(.-open):not(.-scroll-up):not(.-scroll-down) {
      --zenith-header-text-link: #ffffff;

      &.-invert-logo-color {
        // fill logo
        .logo {
          img {
            filter: invert(100);
          }
        }
      }

      .utils {
        button,
        a {
          filter: invert(100);
          transition: none;
        }
      }
    }
  }

  > .container {
    z-index: var(--content-z-index);
  }

  &:not(.-transparent) {
    &:after {
      display: block;
      z-index: 0;
      content: '';
      position: absolute;
      bottom: 0;
      height: 0.1rem;
      width: 100%;
      background: var(--zenith-header-border);
      transition: all 0.2s ease-in-out;
    }
  }

  @include bp(max md) {
    height: 9rem;
    background: var(--zenith-header-mobile-background);
    transition: background 0.2s ease-in-out, height 0s 0.4s;

    & > .container {
      position: absolute;
      height: 100%;
      // overflow: hidden;
      padding-bottom: 0;
      background: transparent;
      transition: background 0.2s ease-in-out, height 0s 0.4s, padding 0s 0.4s;

      .lang,
      .search,
      .menu {
        opacity: 0;
        display: none;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
      }

      nav {
        pointer-events: none;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 9rem;
      background: var(--zenith-header-background);
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }

  &.-open {
    @include bp(max md) {
      background: var(--zenith-header-background);

      &:after {
        opacity: 0;
      }

      & > .container {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100vh;
        background: var(--zenith-header-background);
        padding-bottom: 14rem;
        transition: background 0.2s ease-in-out, height 0s 0s, padding 0s 0s;

        .lang,
        .search,
        .menu {
          opacity: 1;
          pointer-events: all;
        }
        .menu {
          display: flex;
        }
        .lang,
        .search {
          display: block;
        }
        .lang {
          order: 5;
        }
        .search {
          order: 0;
        }
      }
    }
  }

  .logo {
    display: inline-block;
    position: relative;
    z-index: 10;
    top: 0;
    left: 50%;
    padding: 1.8rem 4rem 4px;
    transform: translateX(-50%);
    transition: none;

    h1 {
      margin: 0;
      line-height: 0;
    }

    @include bp(max md) {
      text-align: center;
      height: 9rem;
      left: 50%;
      min-height: 9rem;
      position: relative;
      padding: 3rem 1rem 1rem;
    }

    img {
      width: 12rem;
      @include bp(max md) {
        width: 9.2rem;
      }
      @media (prefers-color-scheme: dark) {
        filter: var(--zenith-img-color-invert);
      }
    }
    &.fade-enter-active,
    &.fade-leave-active {
      transition: all 0.2s ease-in-out;
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
      position: absolute;
    }
  }

  .utils {
    position: absolute;
    top: 3rem;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    transition: opacity 0.2s ease-in-out;
    right: 2.5rem;
    li {
      position: relative;
    }

    @include bp(max md) {
      top: 3.4rem;
      right: 0.5rem;
      pointer-events: all;

      li:first-child {
        display: none;
      }
    }

    a,
    button {
      position: relative;
      display: block;
      color: var(--zenith-black);
      border: none;
      background: none;
      padding: 1rem 1.5rem;

      @include bp(max md) {
        padding: 1rem 0.8rem;
      }

      i {
        display: block;
      }

      .counter {
        position: absolute;
        top: 0.8rem;
        right: 1rem;

        @include bp(max md) {
          top: 0.6rem;
          right: 0.2rem;
        }
      }

      &:after {
        display: block;
        content: '';
        position: absolute;
        bottom: 1rem;
        left: 50%;
        height: 0.1rem;
        width: 0;
        opacity: 0;
        background: var(--zenith-black);
        transform: translateX(-50%);
        transition: $transition-default-fast;
      }

      &:hover,
      &:focus,
      &:active,
      &.-active {
        &:after {
          opacity: 1;
          width: 2rem;
        }
      }
    }
  }

  .menu {
    display: flex;
    width: 100%;
    max-width: 88rem;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    justify-content: space-between;

    @include bp(max lg) {
      width: 100%;
    }

    @include bp(max md) {
      padding: 0;
      flex-direction: column;
    }

    li {
      flex: 1;

      .link {
        position: relative;
        z-index: 40;
        display: block;
        width: 100%;
        padding: 2rem 1rem 1.6rem;
        color: var(--zenith-header-text-link);
        text-transform: uppercase;
        text-align: center;
        border: none;
        background: none;
        outline: none;

        &,
        a,
        button {
          transition: none;
        }

        a {
          color: inherit;
        }

        @include bp(max md) {
          text-align: left;
          font-size: em(14);
          letter-spacing: em(0.2, 14);
          width: calc(100% + 3rem);
          margin: 0 -1.5rem;
          padding: 1.6rem 1.5rem 1.4rem;
          border-bottom: 1px solid var(--zenith-header-border);

          &:focus {
            outline: 1px dashed #383838;
            outline-offset: 4px;
          }
        }

        h2 {
          margin: 0;
          display: inline;
          pointer-events: none;
          font-size: inherit;
          font-weight: inherit;
        }
      }

      @include bp(max md) {
        button.link {
          position: relative;

          &:not(.-cn):before {
            position: absolute;
            right: 1.5rem;
            top: 50%;
            transform: translateY(-50%);
            content: '\e925';
            font-family: 'zenith', sans-serif !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            font-size: em(20);

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }

          &#btn-menu-watches {
            padding-top: 2rem;
            padding-bottom: 0;
            border-bottom: none;

            &:before {
              display: none;
            }
          }
        }
      }
    }

    &_selector {
      position: absolute;
      z-index: 10;
      bottom: 0;
      height: 0.2rem;
      opacity: 0;
      background: var(--zenith-header-text-link);
      pointer-events: none;
      transition: all 0.2s ease-in-out;

      &.-active,
      &.-focus {
        opacity: 1;
      }

      @include bp(max md) {
        display: none;
      }
    }

    &_rich {
      position: absolute;
      z-index: 20;
      left: 0;
      width: 100%;
      text-align: center;
      background: var(--zenith-header-background);

      @include bp(max md) {
        top: 14rem;
        left: 0;
        z-index: 50;
        overflow: hidden;
        background: var(--zenith-header-background);
        transition: all 0.4s ease-in-out;

        .container {
          overflow-y: auto;
          // max-height: calc(100vh - 19rem);

          &.-watches {
            @include bp(max md) {
              & > .row > .col-12:first-child {
                display: flex;
                flex-direction: column;

                .collections {
                  order: 0;
                  flex-wrap: nowrap;
                  overflow-x: auto;
                  padding: 0 1rem;
                  margin-bottom: 1rem;

                  &::-webkit-scrollbar {
                    width: 2px;
                    height: 2px;
                    border-radius: 2px;
                    background-color: white;
                  }

                  &::-webkit-scrollbar-thumb {
                    background: #262626;
                    border-radius: 2px;
                  }
                }

                .btn {
                  order: 0;
                  margin-top: 0;
                  margin-bottom: 2rem;
                }
              }
            }
          }
        }
      }
      .container.-watches {
        .collection {
          width: 20%;

          @include bp(max md) {
            width: 30%;
            min-width: 30%;
            max-width: 30%;
            padding: 2rem 0.5rem 1rem;
          }

          /deep/ h3,
          /deep/ strong {
            @include bp(max lg) {
              font-size: em(12);
              margin-bottom: 2rem;
            }
            @include bp(max md) {
              font-size: em(14);
            }
          }

          /deep/ span {
            @include bp(max lg) {
              display: none;
            }
          }
        }
        .aside:before {
          left: 1rem;
        }
      }

      .container.-straps {
        padding-top: 6rem;
        padding-bottom: 6rem;

        .aside.straps {
          padding-top: 0;
          padding-bottom: 0;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          transition: all 0.4s 0.2s ease-in-out;

          &:before {
            display: none;
          }
        }

        .banner {
          display: block;
          overflow: hidden;
          position: relative;
          border-radius: 0.4rem;
          padding-top: 30%;
          width: 100%;
          height: 0;
          transition: all 0.4s ease-in-out;

          &:after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.6) 100%
            );
          }

          strong {
            position: absolute;
            z-index: 10;
            bottom: 2rem;
            display: block;
            color: var(--zenith-white);
            width: 100%;
            text-align: center;
            font-size: em(14);
            font-weight: bold;
            letter-spacing: em(2, 14);
            line-height: em(19, 14);
            text-transform: uppercase;
          }

          /deep/ .obs {
            position: absolute;
            top: 50%;
            left: 50%;
            object-fit: cover;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              object-fit: cover;
              width: 100%;
              height: 100%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 100vw;
        background: var(--zenith-header-background);
        transform: translateX(-50%);
        border-bottom: 1px solid var(--zenith-header-border);
      }

      .back {
        position: relative;
        text-align: center;
        width: 100%;
        border: none;
        background: var(--zenith-header-background);
        text-transform: uppercase;
        padding: 1.6rem 1.5rem 1.4rem;
        border-bottom: 1px solid var(--zenith-header-border);
        color: var(--zenith-black);

        &:before {
          position: absolute;
          left: 1.5rem;
          top: 50%;
          transform: translateY(-50%);
          content: '\e903';
          font-size: em(20);
          line-height: 1;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          font-family: 'zenith', sans-serif !important;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        @include bp(md) {
          display: none;
        }
      }

      .aside {
        padding-top: 6rem;
        border-top: 1px solid var(--zenith-header-border);

        @include bp(max lg) {
          padding-bottom: 6rem;
        }
        @include bp(max md) {
          padding-top: 0;
          padding-bottom: 0;
        }

        @include bp(lg) {
          border-top: none;

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 1rem;
            width: 0.1rem;
            height: 100%;
            background: var(--zenith-header-border);
          }
        }

        .title {
          display: block;
          margin-bottom: 2rem;
          text-transform: uppercase;
          letter-spacing: em(1.75);
          color: var(--zenith-header-title);
        }

        ul {
          margin: 0 0 3rem;
          padding: 0;
          list-style: none;

          @include bp(max md) {
            margin: 0 0 14rem;
          }

          li {
            margin-bottom: 1rem;

            @include bp(max md) {
              margin-bottom: 0;
            }
          }

          h3 {
            margin: 0;
            color: var(--zenith-header-text-link);
            text-transform: uppercase;
            letter-spacing: em(1.75);
            font-size: inherit;
            font-weight: inherit;

            @include bp(md) {
              position: relative;
              display: inline-block;

              &:before {
                position: absolute;
                content: '';
                display: block;
                height: 0.1rem;
                width: 0;
                background: var(--zenith-header-text-link);
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                transition: all 0.2s ease-in-out;
              }
            }

            &:hover,
            &:focus {
              &:before {
                width: 100%;
              }
            }

            @include bp(max md) {
              display: block;
              position: relative;
              text-align: left;
              width: 100%;
              margin: 0;
              text-transform: uppercase;
              padding: 1.6rem 1.5rem 1.4rem;
              background: var(--zenith-header-background);
              border: none;
              border-bottom: 1px solid var(--zenith-header-border);
            }
          }
        }
      }

      &.fade-leave-active {
        .collections,
        .straps,
        .banner,
        .btn,
        .selection,
        .limited {
          @include bp(md) {
            transition: all 0.4s ease-in-out;
          }
        }
      }

      &.fade-enter,
      &.fade-leave-to {
        .collections,
        .straps,
        .banner,
        .btn,
        .selection,
        .limited {
          @include bp(md) {
            opacity: 0;
            transform: translateY(-2rem);
          }
        }

        @include bp(max md) {
          left: 100%;
        }
      }

      @include bp(max md) {
        &#menu-watches {
          margin: 0 -1.5rem;
          width: auto;
          position: static;
          display: block !important;

          &:before {
            border: none;
          }

          .container.-watches > .row > .col-12:first-child .btn {
            margin-bottom: 1rem;
          }

          .selection {
            border-top: none;
            border-bottom: 1px solid var(--zenith-header-border);
          }

          .container {
            overflow: auto;
            max-height: none;

            .aside {
              ul {
                margin-bottom: 1.5rem;

                a {
                  border: none;
                  padding: 0.5rem 1rem;
                  font-size: em(12);
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    &_brand {
      position: absolute;
      z-index: 20;
      left: 0;
      width: 100%;
      text-align: center;
      background: var(--zenith-white);

      @include bp(max md) {
        top: 14rem;
        left: 0;
        z-index: 50;
        overflow: hidden;
        background: var(--zenith-white);
        transition: all 0.4s ease-in-out;

        .container {
          overflow-y: auto;
          max-height: calc(100vh - 19rem);
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 100vw;
        background: var(--zenith-white);
        transform: translateX(-50%);
      }

      .back {
        position: relative;
        text-align: center;
        width: 100%;
        border: none;
        background: var(--zenith-white);
        text-transform: uppercase;
        padding: 1.6rem 1.5rem 1.4rem;
        border-bottom: 1px solid var(--zenith-header-border);

        &:before {
          position: absolute;
          left: 1.5rem;
          top: 50%;
          transform: translateY(-50%);
          content: '\e903';
          font-size: em(20);
          line-height: 1;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          font-family: 'zenith', sans-serif !important;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        @include bp(md) {
          display: none;
        }
      }

      .aside {
        padding-top: 6rem;
        border-top: 1px solid var(--zenith-header-border);

        @include bp(max md) {
          padding-top: 0;
        }

        @include bp(lg) {
          border-top: none;

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 1rem;
            width: 0.1rem;
            height: 100%;
            background: var(--zenith-header-border);
          }
        }

        .title {
          display: block;
          margin-bottom: 2rem;
          text-transform: uppercase;
          letter-spacing: em(1.75);
        }

        ul {
          margin: 0 0 3rem;
          padding: 0;
          list-style: none;

          li {
            margin-bottom: 1rem;

            @include bp(max md) {
              margin-bottom: 0;
            }
          }

          a {
            color: var(--zenith-black);
            text-transform: uppercase;
            letter-spacing: em(1.75);

            &:hover,
            &:focus {
              color: #cccccc;
            }

            @include bp(max md) {
              display: block;
              position: relative;
              text-align: left;
              width: 100%;
              margin: 0;
              text-transform: uppercase;
              padding: 1.6rem 1.5rem 1.4rem;
              background: var(--zenith-white);
              border: none;
              border-bottom: 1px solid var(--zenith-header-border);
            }
          }
        }
      }

      &.fade-leave-active {
        .collections,
        .btn,
        .selection,
        .limited {
          @include bp(md) {
            transition: all 0.4s ease-in-out;
          }
        }
      }

      &.fade-enter,
      &.fade-leave-to {
        .collections,
        .btn,
        .selection,
        .limited {
          @include bp(md) {
            opacity: 0;
            transform: translateY(-2rem);
          }
        }

        @include bp(max md) {
          left: 100%;
        }
      }
    }

    .collections {
      transition: all 0.4s ease-in-out;
      margin: 0 -1rem;
      padding: 6rem 0 5rem;
      list-style: none;
      display: flex;

      @include bp(max lg) {
        margin: 0 -1.5rem;
      }

      @include bp(max md) {
        flex-wrap: wrap;
        padding: 2rem 0 3rem;
      }

      & + .btn {
        margin-bottom: 6rem;
        width: calc(50% - 1rem);
        transition: all 0.4s ease-in-out;

        @include bp(max md) {
          width: 100%;
          margin-bottom: 4rem;
        }
      }
    }

    .collection {
      flex: 1;
      width: 25%;
      padding: 0 1rem;

      @include bp(max md) {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
        padding: 1rem;
      }

      /deep/ a {
        span,
        h3,
        h2,
        strong {
          margin-bottom: 0.4rem;
          font-size: em(13.9);
          letter-spacing: em(0.2, 13.9);

          @include bp(max md) {
            margin-bottom: 2rem;
          }

          &.h5:not(:last-child) {
            font-size: em(13.9);
            letter-spacing: em(0.2, 13.9);
            margin-bottom: 1.4rem;

            @include bp(max xl) {
              font-size: em(12);
              letter-spacing: em(0.2, 12);
            }

            @include bp(max sm) {
              font-size: em(10);
              letter-spacing: em(0.2, 10);
            }

            & + span {
              display: none;
            }
          }

          &.h5:last-child {
            font-size: em(13.9);
            letter-spacing: em(0.2, 13.9);
            margin-bottom: 1.4rem;
            // min-height: 5rem;

            @include bp(max xl) {
              font-size: em(12);
              letter-spacing: em(0.2, 12);
              // min-height: 5rem;
            }

            @include bp(max md) {
              display: block;
            }
          }

          &:not(.h5):last-child {
            margin-bottom: 2rem;
            font-size: em(10);
            letter-spacing: em(0.17, 10);

            @include bp(max md) {
              display: none;
            }
          }
        }

        &:hover,
        &:focus {
          span {
            // margin-bottom: 0 !important;
          }
        }
      }

      &.-watches {
        /deep/ a {
          &:before {
            display: none;
          }
          h3,
          span,
          strong {
            color: var(--zenith-black);
          }
          &:hover,
          &:focus {
            .collection-item-image {
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    .selection {
      position: relative;
      transition: all 0.4s 0.2s ease-in-out;

      @include bp(max md) {
        padding: 0;
        .title {
          display: none;
        }
      }
    }

    .limited {
      position: relative;
      transition: all 0.4s 0.3s ease-in-out;

      @include bp(max md) {
        padding: 0;
        margin-bottom: 8rem;
      }

      @include bp(lg) {
        padding-top: 3rem;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 1rem;
          right: 0;
          height: 0.1rem;
          background: var(--zenith-header-border);
        }
      }

      a {
        position: relative;
        display: block;
        overflow: hidden;
        border-radius: 0.4rem;

        @include bp(max md) {
          margin: 0 1.5rem;
        }
        /deep/ .limited-edition-image {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
          &:before {
            display: block;
            content: '';
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(var(--zenith-black), 0.2);
            transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
        }

        &:hover /deep/ .limited-edition-image,
        &:focus /deep/ .limited-edition-image {
          &:before {
            opacity: 0;
          }

          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
[data-user-color-scheme='dark'] {
  .header .logo img {
    filter: var(--zenith-img-color-invert);
  }
}

.header .menu li.header-phone-number--mobile .link {
  border-bottom: none;
}

.header-phone-number {
  &--mobile {
    position: relative;
    z-index: 40;

    @include bp(md) {
      display: none;
    }
  }

  &--desktop {
    position: absolute;
    top: 3rem;
    left: 13rem;
  }

  &-wrapper {
    display: flex;
    border: none;
    background: none;
    padding: 1rem 0 1rem 2.4rem;
    font-size: 0.64286em;
    outline: none;
    align-items: center;
    text-transform: uppercase;
    color: var(--zenith-header-text-link);

    &.header-phone-number--desktop {
      @include bp(max md) {
        display: none;
      }
    }
  }

  &-text {
    margin-left: 22px;
    position: relative;
    color: inherit;
    text-decoration: none;

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--zenith-header-text-link);
      transform-origin: center;
      transform: scaleX(0);
      transition: transform 0.2s ease-in-out;
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }
}
</style>
